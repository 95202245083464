<template>
<scale-box>
    <div class="more-box">
        <div class="more-back" @click="back">返回</div>
        <div class="more-top">
            <span>【中国{{omb.zllx}}】</span><span>{{omb.shenqinghao}}</span><span>{{omb.zymc}}</span><span v-if="omb.zlstatus">{{status[omb.zlstatus]}}</span>
        </div>
       <div class="more-sec">
         <div class="more-title">专利公开详情</div>
       </div>
       <div class="more-middle">
          <div :class="active">
             <div @click="change('info')" class="div1">著录项信息</div>
             <!-- <div @click="change('quan')" class="div2">权力要求</div>
             <div @click="change('shu')" class="div3">说明书</div>
             <div @click="change('wen')" class="div4">PDF全文</div> -->
          </div>
       </div>
       <div v-show="active== 'info'">
            <div class="more-ico">
                <i class="el-icon-caret-right"></i>
                <span>著录项</span>
            </div>
            <div class="more-info">
                <div>
                    <div>
                        <span>申请号</span>
                        <span>{{omb.shenqinghao}}</span>
                    </div>
                    <div>
                        <span>公开号</span>
                        <span>{{omb.sqgkh}}</span>
                    </div>
                    <div>
                        <span>申请（专利权）人</span>
                        <span>{{omb.sqrmc}}</span>
                    </div>
                    <div>
                        <span>主分类号</span>
                        <span></span>
                    </div>
                    <div>
                        <span>国省代码</span>
                        <span></span>
                    </div>
                    <div>
                        <span>代理人</span>
                        <span>{{omb.dlr}}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <span>申请日</span>
                        <span>{{omb.sqr}}</span>
                    </div>
                    <div>
                        <span>公开日</span>
                        <span>{{omb.sqgkr}}</span>
                    </div>
                    <div>
                        <span>发明人</span>
                        <span>{{omb.fmr}}</span>
                    </div>
                    <div>
                        <span>分类号</span>
                        <span>{{omb.ipcflh}}</span>
                    </div>
                    <div>
                        <span>地址</span>
                        <span>{{omb.dysqrdz}}</span>
                    </div>
                    <div>
                        <span>代理机构</span>
                        <span>{{omb.dljg}}</span>
                    </div>
                </div>
                
        </div>
        <div style="clear:both;"></div>
        <div class="more-ico">
            <i class="el-icon-caret-right"></i>
            <span>摘要</span>
        </div>
        <div class="more-wen">
           {{omb.zlzy}}
        </div>
        <div class="more-ico">
            <i class="el-icon-caret-right"></i>
            <span>信息查询</span>
        </div>
            <div class="more-wen" style="cursor: pointer;" @click="to">
            官网查询地址
        </div>
        <div class="more-ico">
            <i class="el-icon-caret-right"></i>
            <span>网页检索</span>
        </div>
            <div class="more-wen">
            {{omb.sqrmc}}
        </div>
        <div class="more-ico">
            <i class="el-icon-caret-right"></i>
            <span>学术搜索</span>
        </div>
            <div class="more-wen">
            {{omb.zymc}}
        </div>
       </div>
       <div v-show="active== 'quan'"> 
            <div class="more-ico">
                <i class="el-icon-caret-right"></i>
                <span>权利要求</span>
            </div>
            <div class="more-quan">
                <div>
                    权力要求数量<span>（10）</span>
                </div>
                <div>
                    独立权利要求数量<span>（10）</span>
                </div>   
            </div>
            <div class="more-shi">
                <div class="shi-title">
                    <div>层级视图</div>
                    <div>|</div>
                    <div>文本视图</div>
                </div>
                
            </div>
       </div>
       <div v-show="active== 'shu'"> 
            <div class="more-ico">
                <i class="el-icon-caret-right"></i>
                <span>说明书</span>
            </div>
            <div class="more-wen">
                人工智能伤口评估方法及智能终端
            </div>
            <div class="more-wen">
                【技术领域】
            </div> 
            <div class="more-wen">
                人工智能伤口评估方法及智能终端
            </div> 
            <div class="more-wen">
                【背景技术】
            </div>  
            <div class="more-wen">
                本发明涉及一种人工智能伤口评估综合管理系统，包括撒大大驱动器大大青蛙期待曾多次的大群大群大群大群大群大群达娃强大强大七点七四七四七四七四七四大苏打我顶
起顶起撒大大大上的擦大大大亲卫队请问多少钱我的撒大大顶起顶起我的期待顶起顶起顶起定期的撒大青蛙大全阿萨大大设定为访问方法烦烦烦烦烦烦烦烦烦烦烦烦水水水
水水水水水水水水水水水水水水水撒啊啊啊啊啊啊啊啊啊啊啊啊啊
            </div>
            <div class="more-wen">
                【发明内容】
            </div>
            <div class="more-wen">
                本发明涉及一种人工智能伤口评估综合管理系统，包括撒大大驱动器大大青蛙期待曾多次的大群大群大群大群大群大群达娃强大强大七点七四七四七四七四七四大苏打我顶
起顶起撒大大大上的擦大大大亲卫队请问多少钱我的撒大大顶起顶起我的期待顶起顶起顶起定期的撒大青蛙大全阿萨大大设定为访问方法烦烦烦烦烦烦烦烦烦烦烦烦水水水
水水水水水水水水水水水水水水水撒啊啊啊啊啊啊啊啊啊啊啊啊啊
            </div>
            <div class="more-wen">
                【附图说明】
            </div>
            <div class="more-wen">
                人工智能伤口评估方法及智能终端
            </div>
            <div class="more-wen">
                【具体实施方式】
            </div>
            <div class="more-wen">
                人工智能伤口评估方法及智能终端
            </div>  
        </div>
       
	</div>
    </scale-box>
</template>
<script>
import scaleBox from "../../components/data/sclae-box";
export default {
  name: '',
  data(){
    return {
      tableData:[],
      active:'info',
      omb:{},
      status:{
        '01': '申请中',
        '02': '有效',
        '03': '无效',
      },
      hao:this.$route.query.hao
    }
  },
 
  components: {
   scaleBox
  },
  watch:{
    
    
  },
  methods: {
    change(val){
        this.active = val
    },
    to(){
         window.open('https://cponline.cnipa.gov.cn/')
    },
    back(){
        this.$router.go(-1)
    },
	 getData1(){
           
				this.$http.post(this.GLOBAL.new_url + '/patent/service/data/patentQueryAll',
					{
					"year":  '',
					// "level": this.level,
                    // "province": this.province,
                    // "city": this.city,
                    // "county":this.county,
                    "level":"",
                    "province": "",
                    "city": "",
                    "county":"",
                    "from": 0,
                    "size":20,
                     "zymc":"",
                    "zlzy":"",
                    "fmr":[],
                    "sqrmc":[],
                    "lx":[],
                    "fenlei":[],
                    "sort":"sqr",
                    "orderBy":'ASC',
                    "sqh":this.hao,
                    "text":''
					}
            ).then((res) => {
                // console.log(res)
                if(res.data.data.length!=0){
                    this.tableData.push(...res.data.data)
                    this.tableData.map((x,y)=>{
                    if(x.sqr){
                        x.sqr = x.sqr.split("T")[0]
                    }
                        if(x.sqgkr){
                        x.sqgkr = x.sqgkr.split("T")[0]
                        } 
                    })
                    this.omb = this.tableData[0]
                }
				
                // console.log(this.omb)
              } 
			)		
	 },
    
    
  },
   mounted() {
    this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData1();
  
    },
    beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#1C3E77;')
        },
}
</script>

<style scoped>
	.more-box{
      width: 1800px;
        height: 1000px;
        padding: 48px 0 0 64px;
        background: #FFFFFF;
        margin: 16px auto  0;
        }
    .more-top{
        font-size: 18px;
        margin-bottom: 24px;
    }
    .more-top>span{
        margin-right: 25px;
    }
    .more-top>span:nth-of-type(4){
        display: inline-block;
        width: 160px;
        color: #FFFFFF;
        height: 40px;
        margin-left: 40px;
        line-height: 40px;
        background: #2C7AF9;
        text-align: center;
    }
    .more-sec{
        margin-bottom: 24px;
    }
    .more-title{
        font-size: 18px;
        width: 140px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background: rgba(245,245,245,0.87);
    }
    .more-middle{
        height: 40px;
        margin-bottom: 32px;
    }
    .more-middle>div>div{
        float: left;
        height: 40px;
        margin-right: 16px;
        cursor: pointer;
        background: #DEDEDE;
        line-height: 40px;
        text-align: center;
        color: #666666;
        font-size: 18px;
    }
    .div1{
       width: 122px;
    }
    .div2{
       width: 104px;
    }
    .div3{
       width: 86px;
    }
     .div4{
       width: 103px;
    }
    .info .div1,.quan .div2,.shu .div3,.wen .div4{
        color: #FFFFFF;
        background: #4096FF;
    }
    .more-ico{
        font-size: 18px;
        margin-bottom: 16px;
    }
    .el-icon-caret-right{
        font-size: 28px;
        vertical-align: middle;
        color: #2C7AF9;
        margin-left: -10px;
    }
    .more-ico>span{
        vertical-align: middle;
        margin-left: 4px;
    }
    .more-info{
        font-size: 18px;
        margin: 0 0 24px 20px;
    }
    .more-info>div{
        float: left;
    }
    .more-info>div>div{
        margin-bottom: 16px;
    }
    .more-info>div>div>span{
        display: inline-block;
    }
    .more-info>div>div>span:nth-of-type(1){
        color: #666666;
        width: 175px;
    }
     .more-info>div>div>span:nth-of-type(2){
        color: #4096FF;
        width: 460px;
        vertical-align: middle;
     }
      .more-info>div:nth-of-type(2)>div>span:nth-of-type(2){
        width: 900px;
     }
     .more-wen{
        width: 1450px;
        font-size: 18px;
        color: #666666;
        margin: 0 0 24px 20px;
     }
     .more-quan{
        margin: 0 0 24px 20px;
        height: 40px;
     }
     .more-quan>div{
        float: left;
        width: 211px;
         color: #666666;
        background: #DEDEDE;
        line-height: 40px;
        margin-right: 40px;
        text-align: center;
     }
     .more-quan>div>span{
        color: #2C7AF9;
     }
     .more-shi{
        height: 25px;
        margin: 0 0 6px 36px;
        font-size: 18px;
     }
     .shi-title{
        height: 25px;
     }
     .shi-title>div{
        float: left;
        cursor: pointer;
        color: #666666;
        margin-right: 8px;
     }
     .more-back{
        position: absolute;
        width: 120px;
        height: 40px;
        background: #002C8C;
        box-shadow: inset 0px 5px 15px 1px #1890FF;
        font-size: 20px;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        top:50px;
        right:100px;
        z-index: 100;
     }

</style>


